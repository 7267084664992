import { useMUD } from "./MUDContext"
import { Chat } from "./Chat"
import { getBurnerPrivateKey } from "@latticexyz/common"
import { privateKeyToAccount } from "viem/accounts"
import shapes from './assets/shapes.svg';
import './App.css'

const styleUnset = { all: "unset" } as const

export const App = () => {
	const {
		network: { tables, useStore, walletClient },
		systemCalls: { addTask, toggleTask, deleteTask },
	} = useMUD()

	const account = privateKeyToAccount(getBurnerPrivateKey())

	const tasks = useStore((state) => {
		const records = Object.values(state.getRecords(tables.Tasks))
		records.sort((a, b) => Number(a.value.createdAt - b.value.createdAt))
		return records
	})

	return (
		<>
		            <div className="relative min-h-screen flex flex-col justify-center items-center bg-hero bg-cover">

					<div className="text-center space-y-10">
					<h1 className="font-light text-xl uppercase tracking-wider">Coming soon</h1><h1 className="text-7xl lg:text-9xl font-extrabold">hyper</h1><p className="text-xl lg:text-2xl tracking-wide mx-10 lg:max-w-xl lg:mx-auto">onchain community LLM tuning & RAG</p>
					</div>
		            <img src={shapes} className="absolute w-full left-24 bottom-24 animate-blob2" />
					<div className="relative z-10 mx-10 lg:max-w-xl lg:mx-auto">			<Chat account={account} />
</div>

		
			</div>
		</>
	)
}
